<template>
  <div class="find-extra">
    <h3 class="find-extra__title">Поиск</h3>
    <div class="find-extra__content">

      <label class="find-extra__block">
        <div class="find-extra__block-control">
          <span>Весь список</span>
        </div>
        <textarea
          v-model="strMain"
          class="form-control form-control--textarea"
          rows="10"
          placeholder="Введите"
          @input="findExtra"
        />
      </label>

      <label class="find-extra__block">
        <div class="find-extra__block-control">
          <span>Совпадения</span>
        </div>
        <textarea
          v-model="strMatching"
          class="form-control form-control--textarea"
          rows="10"
          placeholder="Введите"
          @input="findExtra"
        />
      </label>

      <result-output
        :arr-extra="arrExtra"
        :str-extra="strExtra"
      />
    </div>
  </div>
</template>

<script>
import helpers from '@/helpers'
import ResultOutput from "@/components/ResultOutput";

export default {
  name: "find-extra",
  components: {
    ResultOutput
  },
  data () {
    return {
      isTextAreaExtra: true,
      strMain: '',
      strMatching: '',
      strExtra: '',
      arrMain: [],
      arrMatching: [],
      arrExtra: []
    }
  },
  methods: {
    findExtra () {
      this.arrMain = helpers.breakIntoWords(this.strMain)
      this.arrMatching = helpers.breakIntoWords(this.strMatching)

      this.strExtra = this.arrMain.filter(item => !this.arrMatching.includes(item))
          .sort()
          .join(`\n`)

      this.arrExtra = helpers.breakIntoWords(this.strExtra)
    },
    copyText (item = '') {
      if (!this.arrExtra.length) return

      const temp = item || this.strExtra
      navigator.clipboard.writeText(temp);
    },
    setTextArea () {
      this.isTextAreaExtra = !this.isTextAreaExtra
    }
  }
}
</script>

<style lang="scss">
.find-extra {
  &__title {
    margin: 0 0 24px 0;
  }

  &__content {
    display: grid;
    grid-template-columns: 500px;
    grid-gap: 12px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__block-control {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    span + span {
      margin-left: 4px;
    }
  }
}
</style>
